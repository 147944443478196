import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
// import { PiHandshakeDuotone } from "react-icons/pi";



import logo_vib from './../media/img/logo-vib.jpg'
import logo_bg from './../media/img/logo-bg.jpg'
import logo_single from './../media/img/logo-single.png'

import dl_single_nt_nb from './../media/img/dl_single_nt_nb.png'
import dl_vib_4 from './../media/img/dl_vib_4.jpg'
import dl_vib_1 from './../media/img/dl_vib_1.jpg'
import x_icon_d from './../media/icons/x_icon_d.png'
import tg_icon_d from './../media/icons/tg_icon_d.png'
import ig_icon_d from './../media/icons/ig_icon_d.png'

import pop_logo_line from './../media/img/logo-line-no-bg.png'
import pop_logo_intro from './../media/img/logo-intro-1.mp4'
import pop_logo_3d_nb from './../media/img/logo-3d-no-bg.png'
import check_icon from './../media/icons/check.png'

import peki_rocket from './../media/img/peki-rocket.jpg'
import ps_logo from './../media/img/ps-logo.png'
import dt_logo from './../media/img/dt-logo.png'
import { ReactComponent as BscLogo } from './../media/img/bscscan-logo.svg';

import x_icon from './../media/icons/x-icon.png'
import twitter_icon from './../media/icons/twitter-icon.jpg'
import tg from './../media/icons/tg-icon.png'
import discord_ouline from './../media/icons/discord_outline.png'
import discord_black from './../media/icons/discord-black.png'
import copy_icon from './../media/icons/copy.png'
import bsc_icon from './../media/icons/bsc_icon.png'
import meta_icon from './../media/icons/meta-icon.png'
import set_icon from './../media/icons/set-icon.png'

import discord from './../media/icons/discord.png'
import tg_icon from './../media/icons/tg.png'
import twitter from './../media/icons/twitter.png'
import mail_icon from './../media/icons/mail-icon.png'
import dex_icon from './../media/icons/dex-icon.png'

import { htws } from './../utilities/about-htw'


const Home = () => {


    const [htwCount, setHtwcount] = useState(0)



    return ( 
        <div className="">

            <section id="home" className="home">
                <div className="home-cont">
                    <div className="flex row align-center justify-center wrap">
                        <div className="home-text">
                            <div className="home-head-subhead" data-aos="fade-right" data-aos-duration="2000">
                                <h2><span>$DOGITO:</span> THE LITTLE DOGE</h2>
                                <h3>Meet DOGITO, the adorable counterpart to the renowned DOGE, deeply enamored with DOGITA. This charming memecoin is residing on the Binance Smart Chain (BSC) network.</h3>
                            </div>
                            
                            <div className="home-ca-cont">
                               <h3>Contract Address</h3>
                               <div className="home-ca-box">
                                    <p>0x1a2abc897358c3b4e7704ffa659d73b5eb2b38f9</p>
                               </div>
                            </div>
                            
                            <a href="https://pancakeswap.finance/swap?outputCurrency=0x1a2abc897358c3b4e7704ffa659d73b5eb2b38f9&inputCurrency=BNB" target="_blank" className='btn-1'>
                                Buy Token Now
                            </a>
                        </div>

                        <div className="home-img">
                            <div className="flex column overflow-y-hidden">
                                <p className="home-img-by-text">Buy Now</p>
                                <img src={dl_single_nt_nb} className="home-text-logo-ant" alt="" data-aos="zoom-in" data-aos-duration="2000" />
                                <div className="flex row align-center justify-center home-img-line-box">
                                    <div></div>
                                    <p>$DOGITO</p>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="about" id="about">
                <div className="main-frame">
                    <div className="flex row wrap align-center">
                        <div className="about-text" data-aos="fade-right" data-aos-duration="2000">
                            {/* <h2>About Popeye</h2> */}
                            <h3>Introducing Dogito Token: The Little Doge</h3>
                            <p>
                                Meet DOGITO, the adorable counterpart to the renowned DOGE, deeply enamored with DOGITA. 
                                This charming memecoin, residing on the Binance Smart Chain (BSC) network, was conceived 
                                to spread joy and laughter across the crypto community. $DOGITO stands as a true testament 
                                to the power of community-driven projects, where every member plays a vital role in its 
                                development and success. Join us on this whimsical journey as we embrace the 
                                spirit of unity and fun in the world of cryptocurrency!
                            </p>

                            <a href="https://t.me/DogitoCoin" className="about-btn flex row gap-15 justify-center align-center">
                               <span>Join The Community</span> 
                               <img src={tg_icon} alt="" />
                            </a>
                        </div>
                        <div className="about-img" data-aos="fade-left1" data-aos-duration="2000">
                            {/* <video src={pop_logo_intro} muted autoPlay loop /> */}
                            <img src={dl_vib_1} alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="htb" id="htb">
                <div className="main-frame">
                    <div className="htb-header" data-aos="fade-up" data-aos-duration="2000">
                        <h2>Be a Part of the $POPE Movement</h2>
                        <h3>How to buy $POPE</h3>
                    </div>
                    <div className="grid grid-column-2 row grid-column-gap-40 grid-row-gap-20 overflow-y-hidden">


                        <div className="htb-item" data-aos="flip-right" data-aos-duration="2000">
                            <div className="htb-item-inner">
                                <div className="flex justify-center">
                                    <img src={meta_icon} alt="" />
                                </div>
                                <h4>Create MetaMask Wallet and Connect</h4>
                                <p>
                                    Download Metamask or a wallet of your choice from the following list and create a wallet: <br />
                                    <a href="hhttps://www.coinbase.com/" target="_blank" className="display-inline">Coinbase Wallet</a> – Best for Beginners <br />
                                    <a href="https://metamask.io/" target="_blank" className="display-inline">MetaMask</a> – Best for Desktop <br />
                                    <a href="https://trustwallet.com/" target="_blank" className="display-inline">TrustWallet</a> – Best for Mobile <br />
                                    We recommend you confirm you are on the correct website when clicking any links or doing anything related to cryptocurrency. Do not get scammed!
                                </p>
                            </div>
                        </div>

                        <div className="htb-item" data-aos="flip-left" data-aos-duration="2000">
                            <div className="htb-item-inner">
                                <div className="flex justify-center">
                                    <img src={bsc_icon} alt="" />
                                </div>
                                <h4>Buy BNB</h4>
                                <p>
                                    In order to purchase $PEKI, you must have BNB. To acquire BNB, you can purchase 
                                    it from several centralized exchanges such
                                     as <a href="https://coinbase.com/">Coinbase</a>, <a href="https://binance.com/">Binance</a> and <a href="https://kucoin.com/">KuCoin</a>. 
                                    You must then send the BNB from the exchange to your wallet.
                                </p>
                            </div>
                        </div>
                        
                        <div className="htb-item" data-aos="flip-right" data-aos-duration="2000">
                            <div className="htb-item-inner">
                                <div className="flex justify-center">
                                    <img src={set_icon} alt="" />
                                </div>
                                <h4>Connect to PancakeSwap</h4>
                                <p>
                                    Go to Pancakeswap and connect your wallet of choice to the dApp. Paste the $PEKI 
                                    token address into the output currency if it is not already loaded.<br /><br />

                                    The contract address for $POPE is Adddresss.
                                    Any contract addresses other than this are fake and may be a scam/honeypot. Be careful.
                                </p>
                            </div>
                        </div>

                        <div className="htb-item" data-aos="flip-left" data-aos-duration="2000">
                            <div className="htb-item-inner">
                                <div className="flex justify-center">
                                    <img src={pop_logo_line} alt="" />
                                </div>
                                <h4>Swap BNB to Pope</h4>
                                <p>
                                    Now that you have an bnb wallet, you own BNB and you are connected to Pancakeswap, you 
                                    can now swap BNB to POPE. There are no taxes for swaps to and from POPE, 
                                    so you can enjoy your PEKI tokens with no hidden fees!
                                </p>
                            </div>
                        </div>


                    </div>
                </div>
            </section> */}

            <section className="tokenomics" id="tokenomics">
                <div className="main-frame">                    
                    <div className="tk-header overflow-y-hidden" data-aos="fade-right" data-aos-duration="2000">
                        <h2>Total Supply <span>10.000 M</span></h2>
                        <p data-aos="fade-up" data-aos-duration="2000">
                            $DOGITO is a BEP-20 governance token of the Dogito that will empower the community members with voting, governance, and management rights.
                        </p>
                    </div>
                    <div className="row token-table overflow-y-hidden">
                        <div className="flex-100">
                            <center>
                                <table>
                                    <tr>
                                        <th>Specification</th>
                                        <th>Detail</th>
                                    </tr>
                                    <tr>
                                        <td>Token Name:</td>
                                        <td>DOGITO Token</td>
                                    </tr>
                                    <tr>
                                        <td>Ticker Symbol:</td>
                                        <td>$DOGITO</td>
                                    </tr>
                                    <tr>
                                        <td>Blockchain:</td>
                                        <td>Binance</td>
                                    </tr>
                                    <tr>
                                        <td>Contract Type:</td>
                                        <td>BEP-20</td>
                                    </tr>
                                    <tr>
                                        <td>Decimals:</td>
                                        <td>9</td>
                                    </tr>
                                    <tr>
                                        <td>Total Supply:</td>
                                        <td>10.000.000</td>
                                    </tr>
                                </table>
                            </center>
                        </div>
                    </div>
                    <div className="row tax-box">
                        <div className="tax-heading"><h2>$DOGITO Token tax</h2></div>
                    </div>

                    <div className="row token-table overflow-y-hidden">
                        <div className="flex-100">
                            <center>
                                <table>
                                    <tr>
                                        <th colSpan="3">Dogito token tax structure</th>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>BUY</td>
                                        <td>SELL</td>
                                    </tr>
                                    {/* <tr>
                                        <td>Liquidity</td>
                                        <td>1%</td>
                                        <td>1%</td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>Auto Burn</td>
                                        <td>1%</td>
                                        <td>1%</td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>Marketing</td>
                                        <td>4%</td>
                                        <td>4%</td>
                                    </tr> */}
                                    <tr>
                                        <td>TOTAL TAX</td>
                                        <td>5%</td>
                                        <td>11%</td>
                                    </tr>
                                </table>
                            </center>
                        </div>
                    </div>
                </div>
            </section>

            <section className="roadmap" id="roadmap">
                <div className="main-frame">
                    <div className="roadmap-header overflow-y-hidden" data-aos="fade-up" data-aos-duration="2000">
                        <h2>Learn about our path</h2>
                        <h3>The Roadmap of $DOGITO</h3>
                    </div>

                    <div className="roadmap-item-cont grid grid-column-3 grid-column-gap-25 grid-row-gap-10 overflow-y-hidden">
                        <div className="rd-item" data-aos="flip-right" data-aos-duration="2000">
                            <h2>Phase 1</h2>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"><img src={check_icon} alt="" /></div> <p>Create Smart Contract</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"><img src={check_icon} alt="" /></div> <p>Launch $DOGITO Token</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"><img src={check_icon} alt="" /></div> <p>Launch Website</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"><img src={check_icon} alt="" /></div> <p>BSC Trending Campaign</p>
                            </div>
                        </div>

                        <div className="rd-item" data-aos="flip-right" data-aos-duration="2000">
                            <h2>Phase 2</h2>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>Launch "Search for Dogito" Campaign</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>500-1000 Holders</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>Partnership</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>On-going Prizes</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>More Partnership</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>Marketing</p>
                            </div>
                        </div>

                        <div className="rd-item" data-aos="flip-right" data-aos-duration="2000">
                            <h2>Phase 3</h2>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>1500 - 2000 Holders</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>Build the strongest Community in Crypto meme space</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>Pushing for top 500 Top Crypto Tokens by Marketcap</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>Massive meme marketing campaign</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>Empowering our community</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p> Strengthening the DOGITO ecosystem</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="eco" id="eco">
                <div className="wider-frame-percentage">
                    <div className="eco-header" data-aos="fade-up" data-aos-duration="2000">
                        <h2>Ecosystem Tokens</h2>
                    </div>

                    <div className="eco-container">
                        <div className="flex row wrap align-center">
                            <div className="eco-text" data-aos="fade-right" data-aos-duration="2000">
                                <h2>Peki Inu</h2>
                                <p className="eco-pgh">
                                    Peki Inu Token's mission is to harness the unparalleled dedication and creativity of its 
                                    predecessor communities to create a decentralized ecosystem that offers more than just 
                                    a digital currency. It aims to provide real utility. Already 10% burn, and we have an auto burn ca 
                                </p>
                                <div className="flex row justify-center align-center gap-5 copy-address-box">
                                    <img src={copy_icon} alt="" />
                                    <p>0x760539FF254A88B925fd940C8Bc01069cf76069b</p>
                                </div>
                                <div className="flex row gap-15">
                                    <a href="https://pancakeswap.finance/swap?outputCurrency=0x55d398326f99059fF775485246999027B3197955&inputCurrency=0x760539FF254A88B925fd940C8Bc01069cf76069b" 
                                        target="_blank" className='btn-1'
                                    >
                                        Buy Peki Inu
                                    </a>
                                    <a href="https://top100token.com/address/0x760539ff254a88b925fd940c8bc01069cf76069b" target="_blank" className='btn-sp'>
                                        View Chart
                                    </a>
                                </div>
                                <div className="eco-line"></div>
                                <p className="eco-list">
                                    <span>Token name</span>: Peki Inu <br />
                                    <span>Token Ticker</span>: PekiInu <br />
                                    <span>Total supply</span>: 200.000.000.000.000.000 <br />
                                    <span>Total Burned</span>: 20% <br />
                                </p>
                            </div>
                            <div className="eco-img" data-aos="fade-left" data-aos-duration="2000">
                                <img src={peki_rocket} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section className="partners" id="partners">
                <div className="partners-header" data-aos="fade-up" data-aos-duration="2000">
                    <h2>Our Partners</h2>
                </div>

                <div className="mid-frame">
                    <div className="flex row wrap overflow-y-hidden">
                        <a href="https://pancakeswap.finance/swap?outputCurrency=0x55d398326f99059fF775485246999027B3197955&inputCurrency=0x760539FF254A88B925fd940C8Bc01069cf76069b" 
                            target="_blank" className="partners-item flex justify-center" data-aos="fade-right" data-aos-duration="2000"
                        >
                            <img src={ps_logo} className="partners-item-img" alt="" />
                        </a>
                        <a href="https://www.dextools.io/app/en/bnb/pair-explorer/0xa93d6925f1e1243f4c06f8e1c678629c8365b8f8?t=1713098809201" target="_blank" 
                            className="partners-item flex justify-center" data-aos="fade-up" data-aos-duration="2000"
                        >
                            <img src={dt_logo} className="partners-item-img" alt="" />
                        </a>
                        <a href="https://bscscan.com/token/0x760539ff254a88b925fd940c8bc01069cf76069b" target="_blank" 
                            className="partners-item flex justify-center" data-aos="fade-left" data-aos-duration="2000"
                        >
                            <BscLogo className="partners-item-img" />
                        </a>
                    </div>
                </div>
            </section> */}

            <section className="join" id="join">
                <div className="main-frame">
                    <div className="flex">
                        <div className="flex-100 join-bg-1">
                            <div className="join-bg-2">
                                <div className="">
                                    
                                        <div className="flex row gap-10 j-icons" data-aos="fade-up" data-aos-duration="2000">
                                            <a href="https://t.me/DogitoCoin" target="_blank">
                                                <img src={tg_icon} alt="" />
                                            </a>
                                            <a href="https://x.com/dogitocoin" target="_blank">
                                                <img src={twitter} alt="" />
                                            </a>
                                            <a href="" target="_blank">
                                                <img src={mail_icon} alt="" />
                                            </a>
                                        </div>
                                        <div className="join-text ">
                                            <h3 data-aos="fade-right" data-aos-duration="2000">Join our Community</h3>
                                            <p data-aos="fade-left" data-aos-duration="2000">Stay connected to Dogito</p>
                                            <a target="_blank" href="https://t.me/DogitoCoin" className="btn-1">Join Telegram</a>
                                        </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="footer" id="footer">
                <div className="main-container">
                    <div className="flex row justify-space-between align-center footer-row-box">
                        <div className="flex row gap-45">
                            <a href="https://x.com/dogitocoin" target="_blank" className="flex align-center justify-center ft-row-icon-box">
                                <img src={x_icon_d} alt="" />
                            </a>
                            <a href="https://t.me/DogitoCoin" target="_blank" className="flex align-center justify-center ft-row-icon-box">
                                <img src={tg_icon_d} alt="" />
                            </a>
                            {/* <a href="" target="_blank" className="flex align-center justify-center ft-row-icon-box">
                                <img src={ig_icon_d} alt="" />
                            </a> */}
                        </div>

                        <a href="https://pancakeswap.finance/swap?outputCurrency=0x1a2abc897358c3b4e7704ffa659d73b5eb2b38f9&inputCurrency=BNB" target="_blank" className="btn-1">Buy Now</a>
                    </div>
                    <hr />
                    <p className="copyright">DOGITO copyright 2024 - All Rights Reserved</p>
                </div>
            </section>

        </div>
    );
}
 
export default Home;